import {
  Box, Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text, useToast
} from '@chakra-ui/react'
import { DateTimeInput, Select } from '../../../../../components'
import { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { useAuth } from '../../../../../contexts'

export const ExportTask = ({ constructionSite, open, onClose }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const [durationOptions] = useState([
    { value: 'week', label: 'semaine' },
    { value: 'month', label: 'mois' }
  ])
  const [duration, setDuration] = useState(durationOptions[0])
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek'))
  const [endDate, setEndDate] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (startDate) {
      setEndDate(moment(startDate).endOf(duration.value === 'week' ? 'isoWeek' : 'month'))
    }
  }, [startDate])

  useEffect(() => {
    if (startDate) {
      setStartDate(moment(startDate).startOf(duration.value === 'week' ? 'isoWeek' : 'month'))
      setEndDate(moment(startDate).endOf(duration.value === 'week' ? 'isoWeek' : 'month'))
    }
  }, [duration])

  const submit = () => {
    if (!startDate) {
      [!startDate ? 'Le champ date à exporter est obligatoire' : ''].filter(e => e?.length).forEach(error => toast({
        position: 'bottom-right',
        description: error,
        status: 'error',
        duration: 5000,
        isClosable: false
      }))
      return
    }

    setSubmitting(true)

    callApi({
      method: 'get',
      url: `admin/construction-sites/${constructionSite.id}/tasks-export?start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}`,
      catchCallback: err => {
        toast({
          position: 'bottom-right',
          description: err.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: false
        })
        setSubmitting(false)
      },
      preventDisplayApiError: true
    })
      .then(result => {
        if (!result) {
          return
        }

        const toCsv = (arr) => {
          return arr.reduce(function (csvString, row) {
            csvString += row.map(r => (r ?? '').toString().replace('.', ',')).join(';')
            csvString += '\r\n' // ";";//"\n";
            return csvString
          }, '')
        }

        if (result.data.data) {
          const csv = 'data:text/csv;charset=utf-16,\uFEFF' + encodeURIComponent(`${result.data.data.headings.join(';')}\r\n` + toCsv(result.data.data.datas))
          const link = document.createElement('a')
          link.setAttribute('href', csv)
          link.setAttribute('download', `${result.data.data.title}.csv`)
          link.click()
        } else {
          toast({
            position: 'bottom-right',
            description: result.data.message,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }

        onClose()
      })
      .finally(() => setSubmitting(false))
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Exporter la ventilation</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={5} my='5'>
          <Select name={'Exporter par'} value={duration} setValue={setDuration} options={durationOptions} />
          {duration.value === 'week' && (
            <Box style={duration.value === 'week' ? { display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-around' } : { display: 'none' }}>
              <DateTimeInput name='Du' value={startDate} setValue={setStartDate} shouldDisableDate={(date) => date.day() !== 1} />
              <DateTimeInput name='Au' value={endDate} setValue={setEndDate} minDate={startDate} shouldDisableDate={(date) => date.day() !== 0} />
            </Box>
          )}
          {duration.value === 'month' && (
            <Box style={{ display: duration.value === 'month' ? 'initial' : 'none' }}>
              <DateTimeInput name='Mois à exporter' format='MMMM' value={startDate} setValue={setStartDate} views={['year', 'month']} openTo="month" />
            </Box>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button disabled={!startDate} isLoading={submitting} data-variant='solid' onClick={submit}>Télécharger</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}
